import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getVehiclePlates as getVehiclePlatesApi,
} from "../../helpers/backend_helper";
import 'react-toastify/dist/ReactToastify.css';

export const getVehiclePlates = createAsyncThunk("vehicle/getVehiclePlates", async () => {
    try {
        const response = getVehiclePlatesApi();
        return response;
    } catch (error) {
        return error;
    }
});