import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Hls from 'hls.js'; // Import hls.js for HLS streaming
import BreadCrumb from 'Common/BreadCrumb';
import profilePicture from 'assets/images/people/profile-picture.webp';
import suitcaseXray from 'assets/images/people/suitcase-xray.webp';

interface Snapshot {
  id: string;
  face_snapshot: string; // base64 encoded image
  xray_snapshot: string; // base64 encoded image
  created_at: Date;
  updated_at: Date;
}

const PeopleDashboard = () => {
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const [lastSnapshot, setLastSnapshot] = useState<Snapshot | null>(null);
  const [wsStatus, setWsStatus] = useState('initializing');
  const [showVideo, setShowVideo] = useState(false); // Toggle between snapshot and video stream
  const videoRef = useRef<HTMLVideoElement>(null);

  const api_url = process.env.REACT_APP_API_URL || 'http://localhost:8000';
  const hlsUrl = 'http://167.172.91.132:8888/xray/index.m3u8';

  const fetchSnapshots = () => {
    axios.get(`${api_url}/snapshots`)
      .then(response => {
        const snapshots = response.data.map((snapshot: any) => {
          const path_prefix = process.env.REACT_APP_API_URL;
          snapshot.face_snapshot = path_prefix + snapshot.face_snapshot;
          snapshot.xray_snapshot = path_prefix + snapshot.xray_snapshot;
          return snapshot;
        });
        setLastSnapshot(snapshots[0]);
        setSnapshots(snapshots.slice(1));
      })
      .catch(error => console.error('People: Error fetching snapshot data:', error));
  };

  useEffect(() => {
    const ws_url = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:8888/ws';
    let ws: WebSocket;
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;

    const connectWebSocket = () => {
      try {
        ws = new WebSocket(ws_url);
        ws.onopen = () => {
          setWsStatus('connected');
          reconnectAttempts = 0;
        };

        ws.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data);
            if (message.event === 'new_alarm' || message.event === 'new_snapshot') {
              fetchSnapshots();
            }
          } catch (error) {
            console.error('People: Error parsing message:', error, 'Raw message:', event.data);
          }
        };

        ws.onclose = () => {
          setWsStatus('disconnected');
          if (reconnectAttempts < maxReconnectAttempts) {
            reconnectAttempts++;
            setTimeout(connectWebSocket, 3000);
          }
        };

        ws.onerror = (error) => {
          console.error('People: WebSocket error:', error);
          setWsStatus('error');
        };
      } catch (error) {
        setWsStatus('error');
      }
    };

    connectWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  useEffect(() => {
    fetchSnapshots();
  }, []);

  useEffect(() => {
    if (showVideo && videoRef.current) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(hlsUrl);
        hls.attachMedia(videoRef.current);
      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = hlsUrl;
      }
    }
  }, [showVideo]);

  return (
    <React.Fragment>
      <BreadCrumb title="People" pageTitle="Dashboards" />

      <div className="flex justify-between">
        <div className="flex-1 mr-5">
          <div className="rounded-lg overflow-hidden">
            {/* Toggle between snapshot and video stream */}
            {showVideo ? (
              <video ref={videoRef} className="w-full h-640 rounded-lg" autoPlay muted controls />
            ) : (
              <div className="w-full h-640 bg-black flex justify-center items-center">
                <img
                  src={lastSnapshot ? lastSnapshot.xray_snapshot : suitcaseXray}
                  alt="Latest Snapshot"
                  className="rounded-lg h-640"
                />
              </div>
            )}
          </div>
          {/* Toggle Button */}
          <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            onClick={() => setShowVideo(!showVideo)}
          >
            {showVideo ? 'Show Snapshot' : 'Show Video Stream'}
          </button>
        </div>

        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-white text-lg font-bold">Last Snapshot</h3>
            <span className={`text-sm ${
              wsStatus === 'connected' ? 'text-green-500' : 
              wsStatus === 'error' ? 'text-red-500' : 
              'text-yellow-500'
            }`}>
              WebSocket: {wsStatus}
            </span>
          </div>

          <div className="bg-gray-800 rounded-lg p-4 flex items-center">
            <img src={lastSnapshot ? lastSnapshot.face_snapshot : suitcaseXray} alt="Snapshot" className="rounded-lg mb-3 h-36 mr-5" />
            <img src={lastSnapshot ? lastSnapshot.xray_snapshot : profilePicture} alt="Profile" className="rounded-lg h-36 mb-3 mr-5" />
            <p className="text-gray-400">{lastSnapshot ? lastSnapshot.created_at.toString() : 'Loading...'}</p>
          </div>

          <div className="mt-5">
            {snapshots.map((snapshot, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-4 mb-3 flex justify-between items-center">
                <div className="text-white text-lg">{snapshot.id}</div>
                <div className='flex'>
                  <img src={snapshot.face_snapshot || suitcaseXray} alt="Snapshot" className="rounded-lg w-10 h-10 mr-5" />
                  <img src={snapshot.xray_snapshot || profilePicture} alt="Profile" className="rounded-lg w-10 h-10" />
                </div>
                <div className="text-gray-400">{snapshot.created_at.toString()}</div>
              </div>
            ))}

            <div className="text-end">
              <a href="/people" className="text-slate-400 dark:text-zink-200">&rarr; View All</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PeopleDashboard;