import { icon } from "leaflet";
import { Cctv, Car, Users, AlertTriangle, Award, CalendarDays, CircuitBoard, FileText, LifeBuoy, LocateFixed, Mail, Map, MessageSquare, MonitorDot, PackagePlus, PictureInPicture2, PieChart, RadioTower, ScrollText, Share2, ShoppingBag, Table, Trophy, UserRound } from "lucide-react";
const faicai = 'faicai'; 
const braiven = 'braiven'; 
const siam = 'siam'; 

const menuData: any = [
    {
        label: 'MENU',
        isTitle: true,
    },
    {
        id: "locations",
        label: 'Locations',
        link: "/location/${faicai}",
        icon: <Map />,
        subItems: [
            {
                id: 'map', // Unique ID for Map
                label: 'Map',
                link: `/location/${faicai}`, // Correct route for Map
                parentId: "dashboard"
            },
            {
                id: 'Location001', // Unique ID for Map
                label: 'Braiven Office',
                subItems: [
                    {
                        id: 'Location001_Layout',
                        label: 'Layout',
                        link: `/camera/wall/${faicai}`,
                        parentId: 'Location001'
                    },
                    {
                        id: 'Location001_Cameras',
                        label: 'Cameras',
                        link: `/camera/detail/${faicai}`,
                        parentId: 'Location001',
                    },
                    {
                        id: 'Location001_Vehicles',
                        label: 'Vehicles',
                        link: `/vehicle/${faicai}`,
                        parentId: 'Location001',
                    },
                    {
                        id: 'Location001_People',
                        label: 'People',
                        link: `/camera/detail/${faicai}`,
                        parentId: 'Location001',
                    },
                    {
                        id: 'Location001_Alerts',
                        label: 'Alerts',
                        link: `/camera/detail/${faicai}`,
                        parentId: 'Location001',
                    }
                ],
                link: `/location/${faicai}`, // Correct route for Map
                parentId: "locations"
            },
            {
                id: 'Location002', // Unique ID for Map
                label: 'Lumar Office',
                subItems: [
                    {
                        id: 'Location002_Layout',
                        label: 'Layout',
                        link: `/camera/wall/${faicai}`,
                        parentId: 'Location002'
                    },
                    {
                        id: 'Location002_Cameras',
                        label: 'Cameras',
                        link: `/camera/detail/${faicai}`,
                        parentId: 'Location002',
                    },
                    {
                        id: 'Location002_Vehicles',
                        label: 'Vehicles',
                        link: `/vehicle/${faicai}`,
                        parentId: 'Location002',
                    },
                    {
                        id: 'Location002_People',
                        label: 'People',
                        link: `/camera/detail/${faicai}`,
                        parentId: 'Location002',
                    },
                    {
                        id: 'Location002_Alerts',
                        label: 'Alerts',
                        link: `/camera/detail/${faicai}`,
                        parentId: 'Location002',
                    }
                ],
                link: `/location/${faicai}`, // Correct route for Map
                parentId: "dashboard"
            }
        ]
    },
    {
        id: 'cameras',
        label: 'Cameras',
        icon: <Cctv />,
        subItems: [
            {
                id: 'video_wall',
                label: 'Video Wall',
                link: `/camera/wall/${faicai}`,
                parentId: 'cameras'
            },
            {
                id: 'camera_details',
                label: 'Camera Details',
                link: `/camera/detail/${faicai}`,
                parentId: 'cameras',
            }
        ]
    },
    {
        id: 'vehiclesdashboard',
        label: 'Vehicles',
        icon: <Car />,
        link: '/vehicle',
        parentId: "dashboard"
    },
    {
        id: 'peopledashboard',
        label: 'People',
        icon: <Users />,
        link: '/people',
        parentId: "dashboard"
    },
    {
        id: 'alertsdashboard',
        label: 'Alerts',
        icon: <AlertTriangle />,
        link: '/alerts',
        parentId: "dashboard"
    },

];

export { menuData };