import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import BreadCrumb from 'Common/BreadCrumb';

const CameraDetail = () => {
    return (
        <React.Fragment>
            <BreadCrumb title='Camera Detail' pageTitle='Dashboards' />
        </React.Fragment>
    );
};
export default CameraDetail;
