import { createSlice } from "@reduxjs/toolkit";
import {
    getEmployee
} from './thunk';

export const initialState = {
    employeelist: [],
    holidayslist: [],
    departmentslist: [],
    estimateslist: [],
    expenseslist: [],
    leaveManageHRlist: [],
    employeeSalarylist: [],
    attendancelist: [],
    mainAttendancelist: [],
    leaveManageEmployeelist: [],
    paymentslist: [],
    errors: {}
};

const HRManagementSlice = createSlice({
    name: 'HRManagement',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Employee
        builder.addCase(getEmployee.fulfilled, (state: any, action: any) => {
            state.employeelist = action.payload;
        });
        builder.addCase(getEmployee.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
});

export default HRManagementSlice.reducer;