import React from "react";
import TableContainer from "Common/TableContainer";
import { column } from "./index";
import { reactTableData } from "Common/data";
import Flatpickr from 'react-flatpickr';
import { Search, Check, X, UsersRound, UserRoundX, UserRoundCheck, Briefcase, CalendarRange } from 'lucide-react';

const HoverAble = () => {

    const columns: column[] = React.useMemo(
        () => [
            {
                header: 'Snapshort',
                accessorKey: 'snapshort',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Alert',
                accessorKey: 'alert',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Device',
                accessorKey: 'device',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Date & Time    ',
                accessorKey: 'date_time',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
            },
            // {
            //     header: 'Salary',
            //     accessorKey: 'salary',
            //     enableColumnFilter: false,
            //     enableSorting: true,
            // },
        ],
        []
    );

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                <div className="grid grid-cols-1 gap-5 mb-5 xl:grid-cols-12">
                <div className="xl:col-span-2 xl:col-start-1">
                            <div className="relative">
                                <CalendarRange className="absolute size-4 ltr:left-3 rtl:right-3 top-3 text-slate-500 dark:text-zink-200" />
                                <Flatpickr
                                    className="ltr:pl-10 rtl:pr-10 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    options={{
                                        dateFormat: "d M, Y",
                                        mode: "range",
                                    }}
                                    placeholder='Select date'
                                />
                            </div>
                        </div>
                        </div>
                    {/* <h6 className="mb-4 text-15">Hoverable</h6> */}
                    <TableContainer
                        isPagination={true}
                        isSelect={true}
                        isGlobalFilter={true}
                        columns={(columns || [])}
                        data={(reactTableData || [])}
                        customPageSize={10}
                        divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
                        tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
                        theadclassName="border-b border-slate-200 dark:border-zink-500"
                        trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 dark:group-[.hover]:hover:bg-zink-600 [&.selected]:bg-custom-500 dark:[&.selected]:bg-custom-500 [&.selected]:text-custom-50 dark:[&.selected]:text-custom-50"
                        thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 group-[.bordered]:dark:border-zink-500 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left dark:text-zink-50 dark:bg-zink-600 dark:group-[.bordered]:border-zink-500"
                        tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 group-[.bordered]:dark:border-zink-500"
                        PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default HoverAble;