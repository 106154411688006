import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import logoDark from "assets/images/braiven_logo_horizontal.png";
import { loginUser } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import AuthIcon from "pages/AuthenticationInner/AuthIcon";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";

// Memoized selector using reselect
const selectLoginState = createSelector(
  (state: any) => state.Register,
  (state: any) => state.Login,
  (register, login) => ({
    user: register.user,
    success: login.success,
    error: login.error
  })
);

const Login = (props: any) => {
    const dispatch = useDispatch<any>();

    // Use the memoized selector
    const { user, success, error } = useSelector(selectLoginState);

    const formik = useFormik({
        initialValues: {
            email: user?.email || "admin@example.com",
            password: user?.password || "123456",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email format").required("Please enter your email"),
            password: Yup.string().required("Please enter your password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        },
    });

    useEffect(() => {
        document.title = "Login | Pegasus";

        // Add classes to body on mount and remove on unmount
        const bodyElement = document.body;
        bodyElement.classList.add(
            "flex",
            "items-center",
            "justify-center",
            "min-h-screen",
            "py-16",
            "lg:py-10",
            "bg-slate-50",
            "dark:bg-zink-800",
            "dark:text-zink-100",
            "font-public"
        );

        return () => {
            bodyElement.classList.remove(
                "flex",
                "items-center",
                "justify-center",
                "min-h-screen",
                "py-16",
                "lg:py-10",
                "bg-slate-50",
                "dark:bg-zink-800",
                "dark:text-zink-100",
                "font-public"
            );
        };
    }, []);

    return (
        <React.Fragment>
            <div className="relative">
                <AuthIcon />

                <div className="mb-0 w-screen lg:mx-auto lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
                    <div className="!px-10 !py-12 card-body">
                        <Link to="/">
                            <img src={logoDark} alt="Logo" className="block h-6 mx-auto" />
                        </Link>

                        <div className="mt-8 text-center">
                            <p className="text-slate-500 dark:text-zink-200">
                                Sign in to continue to Pegasus.
                            </p>
                        </div>

                        <form
                            className="mt-10"
                            onSubmit={formik.handleSubmit}
                        >
                            {success && (
                                <div className="px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50">
                                    You have <b>successfully</b> signed in.
                                </div>
                            )}
                            {error && (
                                <div className="px-4 py-3 mb-3 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50">
                                    Sign-in <b>failed</b>.
                                </div>
                            )}

                            <InputField
                                label="UserName"
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Enter username or email"
                                formik={formik}
                            />

                            <InputField
                                label="Password"
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Enter password"
                                formik={formik}
                            />

                            <div className="mt-10">
                                <button
                                    type="submit"
                                    className="w-full text-white btn bg-custom-500 border-custom-500 hover:bg-custom-600"
                                >
                                    Sign In
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

// Extracted input field component for reuse
const InputField = ({ label, type, id, name, placeholder, formik }: any) => (
    <div className="mb-3">
        <label htmlFor={id} className="inline-block mb-2 text-base font-medium">{label}</label>
        <input
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 dark:bg-zink-700 dark:focus:border-custom-800"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[name] || ""}
        />
        {formik.touched[name] && formik.errors[name] ? (
            <div className="mt-1 text-sm text-red-500">{formik.errors[name]}</div>
        ) : null}
    </div>
);

export default withRouter(Login);
