import { createSlice } from "@reduxjs/toolkit";
import {
    getVehiclePlates,
} from './thunk';

export const initialState = {
    vehicleList: [],
    vehicleGrid: [],
    errors: {}
};


const VehiclesSlice = createSlice({
    name: 'Vehicles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // List
        builder.addCase(getVehiclePlates.fulfilled, (state: any, action: any) => {
            state.vehicleList = action.payload;
        });
        builder.addCase(getVehiclePlates.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
    });
    }
});

export default VehiclesSlice.reducer;