// WsDialog.js
import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import alertSound from '../../assets/sounds/alert-sound.mp3';

const WsDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [alarmType, setAlarmType] = useState('');
  const [snapshot, setSnapshot] = useState('');
  const [wsStatus, setWsStatus] = useState('initializing');
  const [clientId] = useState(() => `client_${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    // Initialize WebSocket connection
    const ws_url = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:8888/ws';
    let socket: WebSocket;
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    let heartbeatInterval: NodeJS.Timeout;

    const connectWebSocket = () => {
      try {
        // Append client ID as query parameter
        const wsUrlWithClientId = `${ws_url}${ws_url.includes('?') ? '&' : '?'}client_id=${clientId}`;
        socket = new WebSocket(wsUrlWithClientId);
        console.log(`WS Dialog [${clientId}]: Attempting connection to:`, wsUrlWithClientId);

        socket.onopen = () => {
          console.log(`WS Dialog [${clientId}]: Successfully connected`);
          setWsStatus('connected');
          reconnectAttempts = 0;

          // Send initial registration message
          const registrationMsg = {
            type: 'register',
            client_id: clientId
          };
          socket.send(JSON.stringify(registrationMsg));
          console.log(`WS Dialog [${clientId}]: Sent registration message`);

          // Send a ping every 30 seconds to keep connection alive and verify it's working
          heartbeatInterval = setInterval(() => {
            if (socket.readyState === WebSocket.OPEN) {
              console.log(`WS Dialog [${clientId}]: Sending ping...`);
              socket.send(JSON.stringify({ type: 'ping', client_id: clientId }));
            } else {
              console.log(`WS Dialog [${clientId}]: Socket not open during ping attempt. ReadyState:`, socket.readyState);
            }
          }, 30000);
        };

        // Listen for new snapshot events
        socket.onmessage = (event) => {
          console.log(`WS Dialog [${clientId}]: Raw message received:`, event.data);
          
          try {
            const message = JSON.parse(event.data);
            console.log(`WS Dialog [${clientId}]: Parsed message:`, message);

            // Handle ping response if server sends one
            if (message.type === 'pong') {
              console.log(`WS Dialog [${clientId}]: Received pong from server`);
              return;
            }

            if (message.event === 'new_snapshot') {
              const path_prefix = process.env.REACT_APP_API_URL;
              setMessageType(message.event);
              setMessage(`New Snapshot detected at ${message.timestamp}`);
              setSnapshot(path_prefix+message.data.face_snapshot);
              setIsOpen(true);
              new Audio(alertSound).play().catch(error => console.error(`WS Dialog [${clientId}]: Error playing alert sound:`, error));
            }

            if (message.event === 'new_alarm') {
              setMessageType(message.event);
              setAlarmType(message.type);
              const displayMessage = message.event === 'new_alarm' 
                ? `New Alarm: ${message.type} at ${message.timestamp}`
                : `New Snapshot detected at ${message.timestamp}`;
              
              setMessage(displayMessage);
              setIsOpen(true);
              new Audio(alertSound).play().catch(error => console.error(`WS Dialog [${clientId}]: Error playing alert sound:`, error));
            }
          } catch (error) {
            console.error(`WS Dialog [${clientId}]: Error parsing message:`, error, 'Raw message:', event.data);
          }
        };

        socket.onclose = (event) => {
          console.log(`WS Dialog [${clientId}]: Connection closed`, event.code, event.reason);
          setWsStatus('disconnected');
          
          // Clear heartbeat interval
          if (heartbeatInterval) {
            clearInterval(heartbeatInterval);
          }

          // Log the close code
          if (event.code === 1000) {
            console.log(`WS Dialog [${clientId}]: Normal closure`);
          } else if (event.code === 1006) {
            console.log(`WS Dialog [${clientId}]: Abnormal closure - possible server disconnect or network issue`);
          }
          
          // Attempt to reconnect if not max attempts
          if (reconnectAttempts < maxReconnectAttempts) {
            reconnectAttempts++;
            console.log(`WS Dialog [${clientId}]: Attempting to reconnect (${reconnectAttempts}/${maxReconnectAttempts})`);
            setTimeout(connectWebSocket, 3000); // Wait 3 seconds before reconnecting
          }
        };

        socket.onerror = (error) => {
          console.error(`WS Dialog [${clientId}]: WebSocket error:`, error);
          console.log(`WS Dialog [${clientId}]: Socket ready state during error:`, socket.readyState);
          setWsStatus('error');
        };

      } catch (error) {
        console.error(`WS Dialog [${clientId}]: Error creating WebSocket:`, error);
        setWsStatus('error');
      }
    };

    // Initial connection
    connectWebSocket();

    // Cleanup on unmount
    return () => {
      if (socket) {
        if (heartbeatInterval) {
          clearInterval(heartbeatInterval);
        }
        
        console.log(`WS Dialog [${clientId}]: Cleaning up connection`);
        socket.close();
      }
    };
  }, [clientId]);

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={closeDialog}>
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg">
            {/* <Dialog.Title>WebSocket Notification</Dialog.Title> */}
            <Dialog.Description>{message}</Dialog.Description>
            {
              messageType === 'new_alarm' ?
                <div className="text-sm text-gray-500 mt-2">
                  {alarmType === 'smoke_alarm' ? (
                    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
                    <div className="space-y-1 mb-6">
                      <div className="flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                        <h2 className="text-lg font-semibold text-red-500"><span className="text-red-500">การแจ้งเตือนจากอุปกรณ์ตรวจจับควันไฟ</span></h2>
                      </div>
                    </div>
                    
                    <ol className="list-decimal pl-5 space-y-4">
                      <li className="text-base leading-relaxed">
                        เจ้าหน้าที่ตรวจสอบจะต้องระบุชื่อและที่อยู่ของอาคาร และแจ้งว่าระบบแจ้งเตือนเหตุเพลิงไหม้ได้เปิดใช้งานแล้ว
                      </li>
                      <li className="text-base leading-relaxed">
                        เจ้าหน้าที่ยืนยันการเกิดเหตุเพลิงไหม้
                      </li>
                      <li className="text-base leading-relaxed">
                        แจ้งหน่วยงานภายในที่เกี่ยวข้อง
                        <div className="mt-1 text-sm text-gray-600">
                          เบอร์โทรศัพท์: <span className="font-mono">02 348 8811 ต่อ 8811</span>
                        </div>
                      </li>
                      <li className="text-base leading-relaxed">
                        กดปุ่ม <span className="font-semibold">confirm case</span> เพื่อให้หน้าจอ Digital Signage ในแต่ละชั้นแจ้งเตือน แผนการอพยพคนออกจากอาคาร
                      </li>
                      <li className="text-base leading-relaxed">
                        ตรวจสอบกล้อง CCTV ภายในลิฟท์ว่ามีบุคคลากรตกค้างอยู่หรือไม่
                      </li>
                      <li className="text-base leading-relaxed">
                        กดปุ่มยกเลิกการใช้ลิฟท์เพื่อให้ลิฟท์ทุกตัวลงมาจอดที่ชั้น 1
                      </li>
                    </ol>
                   </div>
                  ) : (
                    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
                      <div className="space-y-1 mb-6">
                        <div className="flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-amber-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>
                          <h2 className="text-lg font-semibold text-amber-500"><span className="text-amber-700">มีการเเจ้งเตือนจากเซ็นเซอร์ประตูเเละหน้าต่าง</span></h2>
                        </div>
                      </div>
                      <ol className="list-decimal pl-5 space-y-4">
                        <li className="text-base leading-relaxed">
                          เจ้าหน้าที่ตรวจสอบกล้องบริเวณจุดใกล้เคียง
                        </li>
                        <li className="text-base leading-relaxed">
                          ยืนยันสถานการณ์ที่เกิดขึ้น
                        </li>
                        <li className="text-base leading-relaxed">
                          เเจ้งเจ้าหน้าที่รักษาความปลอดภัย เข้าตรวจสอบพื้นที่
                        </li>
                        <li className="text-base leading-relaxed">
                          ปิดเคส พร้อมส่งออกอีเมล เพื่อรายงานสถานการณ์ที่เกิดขึ้น
                        </li>
                      </ol>
                      </div>
                  )}
                </div>
              :
              <div>
                <div className="text-sm text-gray-500 mt-2">
                  New Snapshot Detected!
                </div>
                {/* also show face_snapshot thumbnail */}
                <div className="mt-4">
                  <img src={snapshot} alt="Snapshot" className="rounded-lg mb-3 h-36 mr-5" />
                </div>
              </div>
            }
            <div className="mt-4">
              <button onClick={closeDialog} className="bg-blue-500 text-white px-4 py-2 rounded-lg">Close</button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default WsDialog;
