import React, { useEffect } from 'react';
import Map from './Map';
import { useNavigate, useParams } from 'react-router-dom';

const Maps = () => {
    const navigate = useNavigate();
    const { site } = useParams();

    useEffect(() => {
        if (site) {
            navigate(`/location/${site}`);
        } else {
            navigate("/location/default"); // or any default route
        }
    }, [site, navigate]);

    return (
        <React.Fragment>
            <Map />
        </React.Fragment>
    );
};

export default Maps;
