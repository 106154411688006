import React, { useEffect } from 'react';
import Map from './Map';
import { useNavigate } from 'react-router-dom';

const Ecommerce = () => {

    const navigate = useNavigate();
    useEffect(() => navigate("/dashboard"), [navigate]);

    return (
        <React.Fragment>
                <Map />
        </React.Fragment>
    );
};

export default Ecommerce;
