import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";

const MapComponent: React.FC = () => {
  const mapContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYnJhem5jbyIsImEiOiJjazA0eWF2MnQwMG15M21ydjBrOXgxaGY1In0.iZrSMIYboG_SfHpZWJ7YsA";
    const initializeMap = () => {
      if (mapContainer.current) {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/braznco/ck055b1ph12b21dpi1orkqwkj",
          center: [100.5, 13.8], 
          zoom: 12,
          maxZoom: 15,
        });
        return () => map.remove();
      }
    };

    initializeMap();
  }, []);

  return (  
    <div
      ref={mapContainer}
      style={{ position: "absolute", top: 0, bottom: 0, width: "100%", left: -1 }}
    />
  );
};

export default MapComponent;