import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getEmployee as getEmployeeApi
} from "../../helpers/fakebackend_helper";
import 'react-toastify/dist/ReactToastify.css';

export const getEmployee = createAsyncThunk("hrManagement/getEmployee", async () => {
    try {
        const response = getEmployeeApi();
        return response;
    } catch (error) {
        return error;
    }
});